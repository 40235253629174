import React from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { JumbotronPropTypes } from '@v10-codeparva/gatsby-theme-v10-tfw/src/components/jumbotron/JumbotronPropTypes';
import {
    defaultButtonStyles,
    defaultTextStyles,
    defaultVariantStyles,
    modalButtonStyles,
    modalTextStyles,
    modalVariantStyles,
} from './constant';
import { CTAButton } from '@v10-codeparva/gatsby-theme-v10-tfw/src/components/jumbotron/CTAButton';
import { CTAButtonTypes } from '@v10-codeparva/gatsby-theme-v10-tfw/src/components/ctaButton';

export const JumboText = ({
    variant,
    title,
    paragraph,
    ctaButtonConfig,
}: JumbotronPropTypes.IJumboText) => {

    const modifiedCtaButtonConfig = [...ctaButtonConfig].map((buttonConfig: CTAButtonTypes.TCallToAction) => {
        let { buttonColor, buttonTypes, textColor } = buttonConfig || {};
        if (buttonTypes === 'outline') {
            buttonConfig.textColor = { base: `brand.${buttonColor}`, sm: `brand.${textColor}` };
        }
        return buttonConfig;
    });

    const Default = () => {
        return (
            <Flex
                {...defaultVariantStyles}
                height={{ base: 'fit-content', sm: '100%' }}
                width={{ base: '100%', sm: '410px', md: '500px' }}
                textAlign={{ base: 'center', sm: 'start' }}
                color={{ base: 'black', sm: 'black' }}
                maxW={'1140px'}
                position='absolute'
                left={{ sm: '9%', md: '15%' }}
                top={{ base: '40vh', sm: 'unset' }}
                alignItems='center'
            >
                <Flex
                    {...defaultTextStyles}
                    position={'absolute'}
                    width={{ base: '100%', sm: '410px', md: '500px' }}
                >
                    <Flex direction='column' display={{ base: 'none', sm: 'flex' }}>
                        {title && <Text textAlign='left' textStyle='h2' margin='24px 0px'>{title}</Text>}
                        {paragraph && <Text textAlign='justify' textStyle={{ base: 'body2', md: 'body1' }}>{paragraph}</Text>}
                    </Flex>
                    {ctaButtonConfig && (
                        <>
                            <Flex justifyContent={{ base: 'center', sm: 'start' }} {...defaultButtonStyles} flexDirection={{ base: 'column', sm: 'row' }} width='100%'>
                                <CTAButton
                                    ctaButtonConfig={modifiedCtaButtonConfig}
                                    breakpoints={{ base: '200px', sm: '145px' }}
                                />
                            </Flex>
                        </>
                    )}
                </Flex>
            </Flex >
        );
    };
    const Modal = () => {
        return (
            <Flex {...modalVariantStyles}>
                <Flex {...modalTextStyles} width={{ base: 'auto', sm: '396px', md: '880px' }}>
                    {title && <Text textStyle={'h1'}>{title}</Text>}
                    {paragraph && <Text>{paragraph}</Text>}
                    {ctaButtonConfig && (
                        <Flex {...modalButtonStyles}>
                            <CTAButton
                                ctaButtonConfig={ctaButtonConfig}
                                breakpoints={{ base: '200px', md: '335px' }}
                            />
                        </Flex>
                    )}
                </Flex>
            </Flex>
        );
    };

    return variant === 'default' ? <Default /> : <Modal />;
};
