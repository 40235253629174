export const containerStyles = {
    height: 'auto',
    margin: '0',
    width: 'auto',
    textStyle: 'body1',
    color: 'text.white',
    wrap: 'wrap' as 'wrap',
    direction: 'column' as 'column',
};

export const carouselButtonStyles = {
    width: '8px',
    height: '8px',
    padding: '0px',
    minWidth: 'none',
    border: '1px solid white',
    cursor: 'pointer',
    mx: '1',
};
export const defaultVariantStyles = {
    gap: '32px',
    zIndex: 1,
    justifyContent: 'center',
};
export const modalVariantStyles = {
    alignItems: 'center',
    justifyContent: 'center',
    direction: 'column' as 'column',
    zIndex: 1,
    height: '100%',
    marginBottom: '5%',
};
export const buttonVariantStyles = {
    bottom: '10',
    width: '100%',
    height: '100%',
    gap: '10px',

};
export const modalTextStyles = {
    backgroundColor: 'background.shadow',
    alignItems: 'center',
    justifyContent: 'center',
    direction: 'column' as 'column',
    gap: '32px',
    color: 'text.grey',
    padding: '40px',
    textAlign: 'center' as 'center',
};
export const defaultTextStyles = {
    direction: 'column' as 'column',
    gap: { sm: '16px' },
};
export const defaultButtonStyles = {
    gap: '28px',
    alignItems: 'center',
    flexWrap: 'wrap' as 'wrap',
    // justifyContent: 'center',
};
export const modalButtonStyles = {
    gap: '31px',
    alignItems: 'center',
    flexWrap: 'wrap' as 'wrap',
    justifyContent: 'center',
};

export const layerStyles = {
    width: '100%',
    height: '100%',
    position: 'absolute' as 'absolute',
    left: '0',
    top: '50%',
    transform: 'translateY(-50%)',
};
export const skewStyles = {
    height: '100%',
    position: 'absolute' as 'absolute',
    top: '50%',
    backgroundColor: 'rgba(255,255,255,.9)',
    transform: 'translate(-50%, -50%) skewX(15deg)'
};
export const carouselImageStyles = {
    width: '100%',
    height: '100%',
    right: '0',
    margin: '0',
};
export const carouselFlexStyles = {
    width: '100%',
    position: 'absolute' as 'absolute',
    justifyContent: 'center',
    left: '0',
};
export const belowButtonStyles = {
    width: '100%',
    gap: '31px',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'background.button',
    padding: '1rem 4rem',
};
