import { localisable as originalLocalisable } from "@v10-codeparva/gatsby-theme-v10-tfw/src/generic/localisable";

export const localisable = {
    ...originalLocalisable,
    userNameExp: "Tenant's Last Name",
    passwordExp: "Tenant's Last 4 digits of Phone Number on File",
    exampleLine1: "John Smith's phone number is 123-456-7891",
    exampleLine2: "John's Username: smith",
    exampleLine3: "John's Password: 7891",
};
